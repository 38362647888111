.dropdown-item:hover {
    background-color: #303950 !important;
    color: white !important;
}
#connect-btn {
    color: #ffffff !important;
    background-color: #556fb4 !important;
    border: 2px solid #556fb4 !important;
  }

.popover-enter-done > button:hover
{
  color: #fff !important;
  background-color: #556fb4 !important;
  border-color: #fff !important;
}
.popover-enter-done > button:hover > a
{
  color: #fff !important;
  background-color: #556fb4 !important;
  border-color: #fff !important;
}