body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
   padding-top: 50px !important;
    max-height: 100%;
    
}
.fa_sm {
  font-size: 15px;
}
.sidebar_recherche_input{
   font-size: 0.8rem !important; }
.pointer{cursor: pointer;}

.tendanceMargin{ padding-left: 50px !important;}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.react-daterange-picker__inputGroup {
  
  font-size: small !important;
}
.width-10{width: 120px !important;}
@media (max-width: 767px) 
{ 
.max-Heigher{

  padding: 20px;
  
}
.sidenav{
  text-align: center !important;
  padding: 10px;}
}
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) 
{ .nav-user-menu { left: 0px !important;}
.max-Heigher{
  width: 74vw;
  max-width: 74vw;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;overflow: scroll;
  max-height: 92vh; 
}
.sidenav{
  width:23vw;
  max-width:23vw;
 height: 92vh;z-index: 200000 !important;
}

 }
 .md-form .prefix {
  top: .25rem;
  font-size: 1.2rem !important;
}
 .grey-text {
  font-weight: 400 !important;
  color: #9e9e9e !important;
}
 /* date range picker */
.react-daterange-picker__wrapper {border: none !important;}
.react-daterange-picker__range-divider,.react-daterange-picker__clear-button{display: none;}
.react-daterange-picker__calendar-button,.react-daterange-picker__inputGroup__input {color: black !important;}
.react-daterange-picker__inputGroup{font-weight: 500;font-size: large;}

/* theme color */
/*.active{color: #303950 !important;}*/
.dream{background-color: #2e4ea2cf !important;}
.dream-text{color: #2e4ea2cf !important;}
input:focus{ border-bottom: 1px solid #303950 !important;box-shadow: 0 1px 0 0 #303950 !important; ;}
.dropdown-item:hover {background-color: #3f51b5 !important;}

.fa { color: #556fb4;}
.btn-outline-info {
  font-weight: 800  !important;;
  color: #303950 !important;
  background-color: transparent !important;
  border: 2px solid #142757 !important;
}

/*** select css */
/**
 * Main wrapper
 */
.select-search {
  width: 100%;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  /*position: relative;*/
  /*z-index: 1;*/
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 25px;
  width: 100%;
  padding: 0 0px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 80%;
  font-weight: 400;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 20px;
  -webkit-appearance: none;
  padding-right: 30px;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;padding-inline-start: 0px;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option {
  display: block;
  height: 26px;
  width: 100%;
 /* padding: 0 16px;*/
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2FCC8B;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 26px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}