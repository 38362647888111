
.trHeader{
    font-weight: 600;
    color: white;
    background-color: #556fb4;
    font-size: larger;
}
.trHeaderTD{border-left: 1px solid #ffffff !important;}
.trHeaderbtm{border-left: 1px solid #ffffff !important;}
.table td, .table th {
    padding: 10px;
    vertical-align: top;
    border: 1px solid #556fb440;
}
.fileContainer {
    background: #fff;
    box-shadow:none !important;
}

.fileContainer .chooseFileButton {
    padding: 10px 20px;
    background: #556fb4;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
    border: none;
}