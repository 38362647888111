.infobull{
    color: #000 !important;
    background-color: white !important;
    padding: 0px !important;
   
    box-shadow: none !important;
}

.smaltotal{
    font-size: 13px;
    font-weight: 500;
    padding-left: .5rem!important;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fff !important; 
}
hr {
    margin-top: 0px !important; 
    margin-bottom: 0px  !important;
}
.navbar {
     padding: 0px !important;
}
.md-form {
   
    margin-top: 0.5rem !important;
}
.modal {
    position: fixed;
    top: 25% !important;
    
    z-index: 200000!important;
}
.md-form {
    margin-top: 0px !important;
   margin-bottom: 0px !important;
}
.Toastify__toast {
    min-height: 0px!important;
}
.modal-title {
    font-weight: 400!important;
    margin-bottom: 3px!important;
    line-height: 1!important;
}
.form-check {
    padding-left: 0px !important;
        font-size: small !important;
    }
    .form-check-input[type="checkbox"]:checked+label:before, label.btn input[type="checkbox"]:checked+label:before {
    
        width: 10px !important;
        height: 1rem !important;
        top: -1px !important;
    
        }
.selected_filtre
{background-color: #556fb4 !important;
    color: white !important;}
.modal-header {
  
    padding-top: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 2px !important;
    padding-left: 15px !important;
}

.tab-content {
    padding: 0px !important;
}
.tab-content>.tab-pane {
    padding: 0px !important;
}
.tab-content>.tab-pane>.card-body {
    padding: 0px !important;
}

.md-pills .nav-link.active {
    color: #fff !important;
    background-color: #303950 !important;
}

.leaflet-container {
    width: 100% !important;
    height: 600px !important;
  }

  .content-default {
    padding-top: 123px !important;
    width: 100%  !important;
    padding-right: 15px;
    padding-left: 15px;

  }
  .content-default_serach_open {
    padding-top: 50px !important;
    width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
}
 